import axios from "axios";
import { Field, Form, Formik } from "formik";
import { Link } from "gatsby-plugin-react-intl";
import request from "graphql-request";
import parse from "html-react-parser";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import * as Yup from "yup";
import "../assets/css/contact.scss";
import ButtonLoading from "../components/buttonLoading";
import Head from "../components/head";
import Layout from "../components/layout";
import Loading from "../components/loading";
import ShowProvider from "../components/showProvider";
import kontaktList from "../images/kontakt-list.svg";

function ContactPage({ pageContext }) {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  const [button, setButton] = useState({});
  const [phrase, setPhrase] = useState({});
  const [sending, setSending] = useState(4);

  useEffect(() => {
    const fetch = async () => {
      const res = await request(
        process.env.GATSBY_GRAPHQL_URL,
        `{
          contact(locale: "${pageContext.intl.language}") {
            pageTitle
            description
            title
            contact_single {
              img {
                url
              }
              description_single
              url
            }
            contactForm{
              nameAndSurname
              email
              companyName
              Phone
              Subject
              Query
            }
          }
          phrase(locale: "${pageContext.intl.language}") {
            validationTooShort
            validationTooLong
            validationRequired
            validationEmail
            validationAcceptTerms
            AcceptTermsForm
            AcceptTerms2Form
          }
          button(locale: "${pageContext.intl.language}"){
            sendquery
          }

        }`
      );
      return res;
    };
    fetch().then((items) => {
      setData(items.contact);
      setPhrase(items.phrase);
      setButton(items.button);
      setLoading(false);
    });
  }, []);
  const strapiHost = process.env.GATSBY_API_URL;
  const min = phrase.validationTooShort;
  const max = phrase.validationTooLong;
  const req = phrase.validationRequired;
  const ema = phrase.validationEmail;
  const formSchema = Yup.object().shape({
    name: Yup.string().min(2, min).max(50, max).required(req),
    company: Yup.string().min(2, min).max(50, max),
    phone: Yup.string(),
    mail: Yup.string().email(ema).required(req),
    subject: Yup.string().min(2, min).max(50, max).required(req),
    message: Yup.string().min(2, min).max(9999, max).required(req),
    terms: Yup.bool().oneOf([true], "").required(req),
  });

  return (
    <Layout bg="#202942" contactUs={false}>
      {loading ? (
        <Loading />
      ) : (
        <ShowProvider>
          <div className="contact">
            <Row className="contact__section1 flex-wrap">
              <Col xs={12} lg={6}>
                <h1>{data.title}</h1>
                <p>{data.description}</p>
                <Row className="contact__section1__icons">
                  {data.contact_single.map(
                    ({ img, description_single, url }, key) => (
                      <Col
                        className="contact__section1__icon-container"
                        xs={12}
                        sm={6}
                        key={key}
                        as="a"
                        href={url}
                        target="_blank"
                      >
                        <img
                          src={`${strapiHost}${img.url}`}
                          alt={description_single}
                          className="contact__section1__image"
                        />
                        <p className="contact__section1__image-desc">
                          {parse(description_single)}
                        </p>
                      </Col>
                    )
                  )}
                </Row>
              </Col>
              <Col xs={12} sm={5} className="d-flex justify-content-center">
                <img src={kontaktList} className="w-100" />
              </Col>
            </Row>
            <Row>
              <Formik
                initialValues={{
                  name: "",
                  mail: "",
                  company: "",
                  phone: "",
                  subject: "",
                  message: "",
                  terms: '',
                }}
                validationSchema={formSchema}
                onSubmit={async (values, { resetForm }) => {
                  setSending(1);
                  const interval = setTimeout(() => {
                    axios.post(`${strapiHost}/mails`, values).then(
                      (response) => {
                        resetForm();
                        setSending(2);
                      },
                      (error) => {
                        setSending(0);
                      }
                    );
                  }, 2000);
                }}
              >
                {({ errors, touched, handleSubmit }) => (
                  <Form className="contact__form">
                    <Row>
                      <Col className="contact__form__input-container">
                        <label htmlFor="name">
                          {data.contactForm.nameAndSurname}
                        </label>
                        <Field
                          id="name"
                          name="name"
                          className="contact__form__input-container__input"
                        />
                        <p className="contact__form__input-container__validation">
                          {errors.name && touched.mail && <>{errors.name}</>}
                        </p>
                      </Col>
                      <Col className="contact__form__input-container">
                        <label htmlFor="mail">{data.contactForm.email}</label>
                        <Field
                          id="mail"
                          name="mail"
                          className="contact__form__input-container__input"
                        />
                        <p className="contact__form__input-container__validation">
                          {errors.mail && touched.mail && <>{errors.mail}</>}
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="contact__form__input-container">
                        <label htmlFor="company">
                          {data.contactForm.companyName}
                        </label>
                        <Field
                          id="company"
                          name="company"
                          className="contact__form__input-container__input"
                        />
                        <p className="contact__form__input-container__validation">
                          {errors.company && touched.company && (
                            <>{errors.company}</>
                          )}
                        </p>
                      </Col>
                      <Col className="contact__form__input-container">
                        <label htmlFor="phone">{data.contactForm.Phone}</label>
                        <Field
                          id="phone"
                          name="phone"
                          className="contact__form__input-container__input"
                        />
                        <p className="contact__form__input-container__validation">
                          {errors.phone && touched.phone && <>{errors.phone}</>}
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="contact__form__input-container">
                        <label htmlFor="subject">
                          {data.contactForm.Subject}
                        </label>
                        <Field
                          id="subject"
                          name="subject"
                          className="contact__form__input-container__input"
                        />
                        <p className="contact__form__input-container__validation">
                          {errors.subject && touched.subject && (
                            <>{errors.subject}</>
                          )}
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="contact__form__input-container">
                        <label htmlFor="message">
                          {data.contactForm.Query}
                        </label>
                        <Field
                          id="message"
                          name="message"
                          component="textarea"
                          rows="4"
                          className="contact__form__input-container__input"
                        />
                        <p className="contact__form__input-container__validation">
                          {errors.message && touched.message && (
                            <>{errors.message}</>
                          )}
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="d-flex flex-column gap-2">
                        <label
                          className="workers__worker__checkbox-container justify-content-start mt-3 mb-0"
                          style={{ color: "white" }}
                          htmlFor="terms"
                        >
                          <Field type="checkbox" name="terms" id="terms" />
                          <div className="workers__worker__checkbox" />
                          {phrase.AcceptTermsForm}
                          {"  "}
                          <Link to="/terms">{phrase.AcceptTerms2Form}</Link>
                        </label>
                        <div className="workers__contact__input-container__validation">
                          {errors.terms && <>{phrase.validationAcceptTerms}</>}
                        </div>
                      </Col>
                    </Row>
                    <Row className="justify-content-center pt-5">
                      <ButtonLoading
                        title={button.sendquery}
                        type="submit"
                        className=" btn btn-xl btn-primary"
                        state={sending}
                        setState={setSending}
                      />
                    </Row>
                  </Form>
                )}
              </Formik>
            </Row>
          </div>
        </ShowProvider>
      )}
    </Layout>
  );
}

export default ContactPage;
